import { Icons } from '../../../components'
import { showUnfinishedFeatures } from '../showUnfinishedFeatures'
import * as CreditCard from './credit-card-guest'
import * as ETransfer from './e-transfer-guest'
import * as DafTransfer from './daf-transfer-guest'
import * as Cheque from './cheque-guest'

export const GUEST_PAYMENT_METHOD_TYPE = {
  creditCard: 'credit-card',
  eTransfer: 'e-transfer',
  cheque: 'cheque',
  dafTransfer: 'daf-transfer',
}

export const GuestPaymentMethodsMeta = {
  [GUEST_PAYMENT_METHOD_TYPE.creditCard]: {
    icon: <Icons.CreditCard />,
    label: 'Credit Card',
    helperText: 'Standard credit card fees will apply.',

    paymentClearingType: 'sync',
    supportsRecurring: false,
    supportsScheduled: false,
    PaymentUI: CreditCard.PaymentUI,
    getPaymentValues: CreditCard.getPaymentValues,
    createContributions: CreditCard.createContributions,
  },
  [GUEST_PAYMENT_METHOD_TYPE.eTransfer]: {
    icon: <Icons.CircleDollar />,
    label: 'E-Transfer',
    helperText: 'A simple transaction from your online banking platform',

    paymentClearingType: 'manual',
    supportsRecurring: false,
    supportsScheduled: false,
    PaymentUI: ETransfer.PaymentUI,
    getPaymentValues: ETransfer.getPaymentValues,
    createContributions: ETransfer.createContributions,
    SuccessUI: ETransfer.SuccessUI,
  },
  [GUEST_PAYMENT_METHOD_TYPE.cheque]: {
    icon: <Icons.MoneyCheckDollar />,
    label: 'Cheque',
    helperText: 'Send money through the post',

    paymentClearingType: 'manual',
    supportsRecurring: false,
    supportsScheduled: false,
    PaymentUI: Cheque.PaymentUI,
    getPaymentValues: Cheque.getPaymentValues,
    createContributions: Cheque.createContributions,
    SuccessUI: Cheque.SuccessUI,
  },
  [GUEST_PAYMENT_METHOD_TYPE.dafTransfer]: {
    icon: <Icons.DafTransfer />,
    label: 'DAF Transfer',
    helperText: 'Funds from Donor Advised Fund (DAF) or Foundation',

    paymentClearingType: 'manual',
    supportsRecurring: false,
    supportsScheduled: false,
    PaymentUI: DafTransfer.PaymentUI,
    getPaymentValues: DafTransfer.getPaymentValues,
    createContributions: DafTransfer.createContributions,
    SuccessUI: DafTransfer.SuccessUI,
  },
}

export const guestPaymentMethods = [
  {
    value: GUEST_PAYMENT_METHOD_TYPE.creditCard,
    ...GuestPaymentMethodsMeta[GUEST_PAYMENT_METHOD_TYPE.creditCard],
  },
  ...(showUnfinishedFeatures
    ? [
        {
          value: GUEST_PAYMENT_METHOD_TYPE.eTransfer,
          ...GuestPaymentMethodsMeta[GUEST_PAYMENT_METHOD_TYPE.eTransfer],
        },
        {
          value: GUEST_PAYMENT_METHOD_TYPE.cheque,
          ...GuestPaymentMethodsMeta[GUEST_PAYMENT_METHOD_TYPE.cheque],
        },
        {
          value: GUEST_PAYMENT_METHOD_TYPE.dafTransfer,
          ...GuestPaymentMethodsMeta[GUEST_PAYMENT_METHOD_TYPE.dafTransfer],
        },
      ]
    : []),
]
